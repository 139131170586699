* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

body {
    background-color: #fff;
    color: #333;
}
/*######################### General styling ###############################*/
body {
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
    color: #333;
}
/********************* Home Section *****************/
/* Home Section Container Styling */
.home-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure it takes up the full width of the viewport */
    margin: 0; /* Remove any potential margin */
    padding: 0; /* Remove any potential padding */
    overflow-x: hidden; /* Prevent the content from going beyond the viewport */
}

.home-section h3 {
    font-family: 'windsong', cursive;
    margin-bottom: 30px;
    font-size: 1.75rem;
    color: #333;
}



/* Responsive home image */
.home-image {
    width: 70%; /* Ensure it takes up the full width of its container */
    max-width: 100%; /* Ensure it doesn't exceed its container's width */
    height: auto; /* Maintain the aspect ratio */
    margin: 0 auto; /* Center the image if necessary */
    object-fit: contain; /* Keep aspect ratio intact */
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
    .home-image {
        width: 100%; /* Ensure the image fits the full screen width */
        height: auto; /* Keep the aspect ratio */
        object-fit: cover; /* Fit the width of the screen, cropping as needed */
        padding: 15px; /* No extra padding */
        margin: 0 auto; /* Ensure it's centered */
    }
}
/******************************* NavBar ***********************/

/* NavBar */
.navbar {
    background-color: #839161;
    color: #58181F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
}

.logo-container {
    display: flex;
    align-items: center;
}

.profile-image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.logo {
    font-family: 'Times New Roman', serif;
    font-size: 2rem;
    font-weight: bold;
    color: #58181F;
    margin-left: 10px;
}

.nav-links {
    list-style: none;
    display: flex;
    align-items: center;
}

.nav-links li {
    margin-left: 20px;
}

.nav-links a {
    text-decoration: none;
    color: #58181F;
    font-size: 1.2rem;
    padding: 10px;
    transition: color 0.3s;
}

.nav-links a:hover {
    color: #555;
}

/******************************* Dropdown Menu ***********************/

/* Dropdown Menu */
.dropdown {
    position: relative;
}

.dropbtn {
    background-color: transparent;
    color: #58181F;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    transition: color 0.3s;
}

.dropbtn:hover {
    color: #555;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #839161;
    min-width: 160px;
    max-width: 250px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content li {
    padding: 6px 0;
}

.dropdown-content li a {
    text-decoration: none;
    color: #58181F;
    font-size: 1rem;
    padding-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-content li a:hover {
    background-color: #555;
    color: #fff;
}

/******************************* Responsive Design ***********************/

/******************************* Responsive Design ***********************/

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px 10px; /* Reducing padding to fit better */
    }

    .nav-links {
        width: 100%;
        justify-content: space-around; /* Spread links evenly */
        display: flex;
        flex-wrap: wrap; /* Allow links to wrap if necessary */
    }

    .nav-links li {
        margin: 0 5px; /* Reduced margin between items */
    }

    .nav-links a {
        font-size: 0.9rem; /* Slightly smaller font for the links */
        padding: 5px 8px; /* Reduced padding for better fit */
    }

    .profile-image img {
        width: 45px;
        height: 45px; /* Slightly smaller profile image */
    }

    .logo {
        font-size: 1.4rem; /* Reduce logo size */
    }

    /* Adjustments for Dropdown Menu on smaller screens */
    .dropdown-content {
        min-width: 100%;
        max-width: 100%;
        padding: 5px;
    }

    .dropdown-content li a {
        font-size: 0.85rem;
        padding-left: 5px;
    }

    .dropdown-content li {
        padding: 5px 0;
    }

    /* Adjust the gallery button size */
    .dropdown .dropbtn {
        font-size: 0.9rem; /* Reduce the font size of the gallery button */
        padding: 5px 10px; /* Reduce padding */
    }
}

/* Additional media query for very small screens (576px or smaller) */
@media (max-width: 576px) {
    .nav-links {
        justify-content: center; /* Center the links on very small screens */
        flex-wrap: wrap; /* Allow links to wrap */
    }

    .nav-links li {
        margin: 3px 5px; /* Even tighter spacing between links */
    }

    .nav-links a {
        font-size: 0.75rem; /* Smaller font for very small screens */
        padding: 4px 6px; /* Reduced padding for a more compact design */
    }

    .logo {
        font-size: 1.2rem; /* Reduce logo size even more for very small screens */
    }

    /* Gallery button adjustments */
    .dropdown .dropbtn {
        font-size: 0.75rem; /* Smaller font size for the dropdown button */
        padding: 2px 3px; /* Reduced padding to ensure proper fit */
    }

    /* Dropdown content styling */
    .dropdown-content li a {
        font-size: 0.8rem; /* Even smaller font for very small screens */
        padding-left: 1px;
    }

    .dropdown-content li {
        margin-left: 2px;
        padding: 0px 0px;
    }
}
/*###################### Footer styling ##################################*/
footer {
    background-color: #f1f1f1;
    color: #333;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    position: relative;

}

.social-icons {
    margin-top: 0px;
    margin-bottom: 10px;
}

.social-icons a {
    margin: 0 15px;
    font-size: 24px;
    color: #333;
    transition: color 0.3s ease;
    text-decoration: none;
}

.social-icons a:hover {
    color: #555;
}

.fab {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

/* ######################### Gallery Section ################################*/
/* Gallery Section */
.gallery-section {
    padding: 50px;
    text-align: center;
}

.gallery-section-title h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #58181F; /* Title color aligned with the site's theme */
}

.scroll-navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.scroll-navigation button {
    background-color: #58181F;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-navigation button:hover {
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Image Grid */
.image-grid {
    display: grid;
    gap: 20px;
    padding: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Increased the minimum size */
    justify-items: center; /* Center the images horizontally */
    grid-auto-rows: auto; /* Ensure the rows adjust dynamically */
    color: gray;

}

/* Image Item */
.image-item {
    text-align: center;
    width: 100%;
    position: relative; /* Make this container relative for positioning */
    margin-bottom: 2em;
    color: gray;
}

/* Default Image Styling */
.gallery-image {
    width: 100%;
    max-width: 650px;
    height: auto;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    object-fit: contain; /* Maintain aspect ratio without cropping */
}
/* Image Description */
.image-description {
    margin-top: 10px;
    padding-bottom: 15px;
    font-size: .85rem;
    color: #333;
}

/* Image Title */
.image-title {
    top: 0; /* Stick to the top of the image */
    left: 0;
    right: 0;
    padding: 10px; /* Add some padding for better readability */
    /* add italics to the title */
    font-style: italic;
    color: black; /* White text for readability */
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    border-radius: 8px 8px 0 0; /* Optional: match the border-radius of the image */
}


/* Image Description */
.image-description {
    margin-top: 10px;
    padding-bottom: 15px;
    font-size: .85rem;
    color: #333;
}


/* Responsive layout for smaller screens */
@media (max-width: 768px) {
    .image-grid {
        gap: 15px; /* Reduced gap for smaller screens */
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Adjusted to fit better on small screens */
    }

    .gallery-image {
        max-height: 350px; /* Ensure a balanced height on small screens */
        object-fit: contain; /* Ensure no cropping on smaller screens */
    }

    .image-description {
        font-size: 0.75rem; /* Adjust font size on small screens */
    }
}
/************************* About Section********************/
/* About Section */
.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 50px 0;
    background-color: #f9f9f9;
}

.about-box {
    background-color: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    padding: 40px;
    max-width: 800px;
    width: 100%;
    margin: 0 50px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Profile photo - rounded square */
.profile-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 15%; /* Makes the image a rounded square */
    border: 3px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    float: right; /* Allow the text to wrap around the image */
    margin-left: 20px; /* Space between the image and the text */
    margin-bottom: 20px; /* Space at the bottom of the image */
    background-color: #fff;
}

.about-box h2 {
    font-size: 2.5rem;
    font-family: 'Georgia', serif;
    color: #333;
    margin: 0; 
    margin-bottom: 20px;
}

.about-box p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    color: #555;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
    .about-box {
        margin: 0 20px;
        padding: 30px;
    }

    .profile-photo {
        width: 120px;
        height: 120px;
        float: none; /* Stop floating on smaller screens */
        display: block;
        margin: 0 auto 20px auto; /* Center the image on smaller screens */
    }

    .about-box h2 {
        font-size: 2rem;
    }

    .about-box p {
        font-size: 1rem;
    }
}



/*###################### Contact Section ############################*/
.contact-section {
    padding: 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #58181F;
}

.btn-submit {
    padding: 10px 20px;
    background-color: #58181F;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.btn-submit:hover {
    background-color: #333;
}

.success-message {
    color: green;
    margin-top: 15px;
}

.error-message {
    color: red;
    margin-top: 15px;
}





/* Exhibition Section Styling */
.exhibition-section {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

/* Exhibition Box Styling */
.exhibition-box {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

/* Hover effect for the exhibition box */
.exhibition-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}

/* Title and Text Styling */
.exhibition-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #58181F;
}

.exhibition-location,
.exhibition-dates,
.exhibition-cost {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #839161;
}

.exhibition-info {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #333;
}

/* Link Styling */
.exhibition-link {
    text-decoration: none;
    color: #58181F;
    font-size: 1rem;
    font-weight: 600;
    background-color: #839161;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.exhibition-link:hover {
    background-color: #555;
    color: #fff;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .exhibition-box {
        padding: 20px;
        max-width: 100%;
    }

    .exhibition-title {
        font-size: 1.75rem;
    }

    .exhibition-location,
    .exhibition-dates,
    .exhibition-cost {
        font-size: 1rem;
    }

    .exhibition-info {
        font-size: 0.9rem;
    }

    .exhibition-link {
        padding: 8px 12px;
        font-size: 0.9rem;
    }
}